import React from 'react';
import {
  Button,
  Select,
  MenuItem,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextareaAutosize,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { validEmail } from '../../../utils/validate';

import theme from '../../../theme';
import styles from './KPTextfield.module.css';
// import { photoRender } from '../../../utils/photoRender';

function KPTextfield(props) {
	const {
		id,
    note,
		type,
		label,
		value,
		options,
		required,
		handleChangeForm,
	} = props;

  const error = props.error && (!value || (type === 'email' && !validEmail(value)));

  var textfieldType = (type === 'int' || type === 'float') ? 'number' : 'text';
  if (type === 'password') textfieldType = type;

  // if (props.render) return(props.render(error));
  if (props.render) return(props.render);

  if (type === 'richText' && !!value) return (
    <div id='ck-container'>
      {props.labelRender || (
        <Typography id={id} className="kpt-textfield-label">
          {label}
          {required ? <span style={{ color: theme.palette.secondary.main }}>*</span> : ""}
          {label ? ":" : ""}
        </Typography>
      )}
      <CKEditor
        data={value}
        editor={ClassicEditor}
        onChange={(_e, editor) => handleChangeForm(id, editor.getData(), type)}
      />
      {error && <Typography style={{fontSize: 12, color: '#f9625b' }}>Không được để trống!</Typography>}
    </div>
  );

  if (type === 'file') {
    console.log(value);
    
    return (
      <>
        {(Array.isArray(value) && value.length > 0) ? (
          <>
            <DropzoneArea
              classes={{
                root: styles.dropzoneRoot,
                text: styles.dropzoneText,
                icon: styles.dropzoneIcon,
              }}
              filesLimit={props?.filesLimit || 1}
              initialFiles={value}
              maxFileSize={10485760}
              useChipsForPreview={!(props?.acceptedFiles?.length === 1 && props?.acceptedFiles?.[0] === 'image/*')}
              acceptedFiles={props?.acceptedFiles || []}
              dropzoneProps={{ disabled: props?.disabled }}
              onChange={(files) => handleChangeForm(id, files, type)}
              dropzoneText={props.labelRender || label || (
                <Typography variant='body1'>
                  <b>Upload file minh chứng tại đây</b>
                </Typography>
              )}
              previewChipProps={{ style: { maxWidth: '75%' } }}
              previewGridProps={{
                container: {
                  spacing: 1,
                  direction: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                },
                item: { style: { width: "100%" } }
              }}
              getFileAddedMessage={(fileName) => `Đã chọn file: ${fileName}`}
              getFileRemovedMessage={(fileName) => `Đã xóa file: ${fileName}`}
              getFileLimitExceedMessage={(filesLimit) => `Kích thước file tối đa: ${filesLimit}!`}
            >
          
            </DropzoneArea>
            {error && <Typography style={{fontSize: 12, marginTop: 8, color: '#f9625b' }}>Không được để trống</Typography>}
          </>
        ) : (
          <DropzoneArea
              classes={{
                root: styles.dropzoneRoot,
                text: styles.dropzoneText,
                icon: styles.dropzoneIcon,
              }}
              filesLimit={props?.filesLimit || 1}
              maxFileSize={10485760}
              useChipsForPreview={!(props?.acceptedFiles?.length === 1 && props?.acceptedFiles?.[0] === 'image/*')}
              acceptedFiles={props?.acceptedFiles || []}
              dropzoneProps={{ disabled: props?.disabled }}
              onChange={(files) => handleChangeForm(id, files, type)}
              dropzoneText={props.labelRender || label || (
                <Typography variant='body1'>
                  <b>Upload file minh chứng tại đây</b>
                </Typography>
              )}
              previewChipProps={{ style: { maxWidth: '75%' } }}
              previewGridProps={{
                container: {
                  spacing: 1,
                  direction: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                },
                item: { style: { width: "100%" } }
              }}
              getFileAddedMessage={(fileName) => `Đã chọn file: ${fileName}`}
              getFileRemovedMessage={(fileName) => `Đã xóa file: ${fileName}`}
              getFileLimitExceedMessage={(filesLimit) => `Số lượng file tối đa: ${filesLimit}!`}
            />
        )}
      </>
    );
  }

  if (type === 'button') return (
    <Button
      id={id}
      fullWidth
      disabled={props?.disabled}
      color={props?.color || 'default'}
      variant={props?.variant || 'outlined'}
      style={{ marginTop: 26 }}
      onClick={props?.onClick}
    >
      {label}
    </Button>
  );

  if (type === 'submit') return (
    <Button
      id={id}
      fullWidth
      type='submit'
      color='secondary'
      variant='contained'
      disabled={props?.disabled}
      style={{ marginTop: 26 }}
    >
      {label}
    </Button>
  );

	return (
		<>
      {props.labelRender || (
        <>
          {label && (
            <Typography id={id} className="kpt-textfield-label">
              {label}
              {required ? <span style={{ color: theme.palette.secondary.main }}>*</span> : ""}
              {label ? ":" : ""}
            </Typography>
          )}
        </>
      )}

      {options ? (
        <Select
          fullWidth
          size='small'
          error={error}
          variant='outlined'
          value={value || ''}
          disabled={props?.disabled}
          style={{ backgroundColor: 'white' }}
          SelectDisplayProps={{ style: { padding: 8 } }}
          MenuProps={{
            getContentAnchorEl: null,
            MenuListProps: { style: { padding: 0 } },
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
          }}
          onChange={(e) => handleChangeForm(id, e)}
        >
          {props.options.map((opt) => (
            <MenuItem key={opt.key} value={opt.value}>{opt.title}</MenuItem>
          ))}
        </Select>
      ) : (
        <>
          {props?.height ? (
            <TextareaAutosize
              className={styles.textArea}
              disabled={props?.disabled}
              minRows={props?.height}
              value={value}
              placeholder={props?.placeHolder}
              onChange={(e) => handleChangeForm(id, e, type)}
            />
          ) : (
            <OutlinedInput
              fullWidth
              size='small'
              error={error}
              // value={(textfieldType === 'number' && !value) ? 0 : value}
              // value={textfieldType === 'number' ? 0 : value}
              value={value}
              variant='outlined'
              type={textfieldType}
              rows={props?.height}
              disabled={props?.disabled}
              multiline={!isNaN(props?.height)}
              placeholder={props?.placeHolder}
              style={{ padding: 0, backgroundColor: 'white' }}
              inputProps={{ style: {
                padding: 8,
                textTransform: type === 'uppercase' ? 'uppercase' : 'none'
              } }}
              endAdornment={
                props.type?.includes('password') && (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => handleChangeForm(`visible${id.substring(8)}`)}>
                      {props.type !== 'password' ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }
              onChange={(e) => handleChangeForm(id, e, type)}
            />
          )}
        </>
      )}
      {error && <Typography style={{fontSize: 12, color: '#f9625b' }}>
        {!value ? 'Không được để trống' : 'Phải đúng định dạng (VD: example@gmail.com)'}
      </Typography>}
      {note && !error && <Typography><i>{note}</i></Typography>}
    </>
  );
}

export default KPTextfield;
