/* eslint-disable */
import dayjs from 'dayjs';
import {
  Box,
  Card,
  Grid,
  Button,
  Checkbox,
  IconButton,
  Typography,
  FormControlLabel,
  Divider,
  TextField,
  OutlinedInput, 
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  CardContent,
  DialogTitle,
} from '@material-ui/core';
import { Add, Close, Edit, Info, Visibility, WarningRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';
import InputActives from '../../ActivesField/InputActives';

import { ROUTER, FORM_CONSTANTS, ARCHIVEMENT_TYPE, KEYS, SECONDARY_COLOR, CATEGORY, APP_SCENE } from '../../../../constants';
import { fileActions, userActions, formActions } from '../../../../actions';

import styles from './DangKyHoSoUpdate.module.css';

import theme from '../../../../theme';
import noImage from '../../../../assets/image/app/no-image.png';
import fileCongVanMau from "../../../../assets/docs/NSKH2024_Mẫu công văn giới thiệu.docx";

import KPTable from '../../../../components/KPTComponents/KPTable';
import KPTextform from '../../../../components/KPTComponents/KPTextform';
import KPTextfield from '../../../../components/KPTComponents/KPTextfield';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
// import ThanhTichInsert from '../../ThanhTichInsert/ThanhTichInsert';
import { columnRender } from '../../../../utils/nstb/columnRender';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import ThanhTichAlert from '../../../AppLayout/ThanhTichAlert/ThanhTichAlert';
import ThanhTichInsert from '../../../XHNV/ThanhTichInsert/ThanhTichInsert';
import { jumpToReleventDiv } from '../../../../components/KPTComponents/utils';
import Loading from '../../../../components/CustomComponents/Loading';
import { fileRender, photoRender } from '../../../../utils/photoRender';
import { capitalizeFirstLetter } from '../../../../utils';

export default function DangKiHoSo() {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

  const { FIELD_PTNS_OPTIONS, POLITICAL_UNION_OPTIONS } = FORM_CONSTANTS;

  const [files, setFiles] = useState({});
  const [dialog, setDialog] = useState(null);
  const [values, setValues] = useState(null);
  const [emptyKey, setEmptyKey] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [acceptRules, setAcceptRules] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const [reward, setReward] = useState([])
  const [socialActivity, setSocialActivity] = useState([]);
  const [majorAchievement, setMajorAchievement] = useState([]);

  const profile = useSelector((state) => state.profileReducer.profile);
  const seasonList = useSelector(state => state.seasonReducer.seasonList);
  const application = useSelector((state) => state.formReducer.applicationList?.[0] || null);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);

  const loading = useSelector((state) => (
    state.userReducer.loading
    || state.formReducer.loading
    || state.profileReducer.loading
    || uploadingFiles
  ));

  const rewards = useSelector((state) => state.rewardReducer.personalRewardList?.map((e) => ({
    ...e, decisionDay: dayjs(e?.decisionDay).format('DD/MM/YYYY')
  })));

  // ---------------- ARCHIVEMENTS ---------------- //
  const achievementData = useSelector((state) => ({
    [ARCHIVEMENT_TYPE.SOLUTION]: state.solutionReducer.personalSolutionList.filter((e) => ['sc', 'scqg', 'gp'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.SOLUTION + CATEGORY.PTNS]: state.solutionReducer.personalSolutionList.filter((e) => ['ptns-sc', 'ptns-scqg', 'ptns-gp'].find((type) => type === e.type)),

    [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT]: state.reportsReducer.personalReportList,

    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH]: state.researchReducer.personalResearchList,
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.DOMESTIC),
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.INTERNATIONAL),

    [ARCHIVEMENT_TYPE.REWARD]: rewards,
    [ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT]: rewards.filter((e) => ['g1', 'g2', 'g3-1'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG]: rewards.filter((e) => ['g3-2', 'g4', 'g5'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS]: rewards.filter((e) => ['nstb-g1', 'nstb-g2', 'nstb-g3'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB]: rewards.filter((e) => ['nstb-n1', 'nstb-n2', 'nstb-n3', 'nstb-n4'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT]: rewards.filter((e) => ['nstb-n5', 'nstb-n6', 'nstb-n7'].find((type) => type === e.type)),
  }));

  const handleFileChange = (e, name) => setFiles({ ...files, [name]: e });

  const renderUploadFile = (title, value) => {
    const url = values?.[`upload${value[0].toUpperCase() + value.slice(1)}`];
    return (
      <>
        <Typography className='kpt-textfield-label'>
          {title}
          <span style={{ color: theme.palette.secondary.main }}>*</span>:
        </Typography>
        {url ? (
          <OutlinedInput
            fullWidth
            size='small'
            value={url.slice(url.indexOf('.') + 1)}
            variant='outlined'
            style={{ paddingRight: '0', backgroundColor: 'white', height: '35px' }}
            endAdornment={
              <Box
                display='flex'
                justifyContent='space-around'
                alignItems='center'
                // style={{ padding: '0 12px' }}
              >
                <Divider orientation='vertical' style={{ height: 35 }} />
                <IconButton
                  edge='center'
                  color='secondary'
                  title='Chi tiết minh chứng'
                  onClick={() => setPreviewFile(url)}
                >
                  <Visibility />
                </IconButton>
                <Divider orientation='vertical' style={{ height: 35 }} />
                <IconButton
                  edge='center'
                  color='primary'
                  title='Chỉnh sửa minh chứng'
                  onClick={() => {
                    setValues({
                      ...values,
                      [`upload${value[0].toUpperCase() + value.slice(1)}`]: null
                    })
                  }}
                >
                  <Edit />
                </IconButton>
              </Box>
            }
          />
        ) : (
          <>
            <Card style={{ borderColor: '#b3b9c3', padding: '5.5px 8px' }}>
              <input
                type='file'
                style={{ width: '100%' }}
                onChange={(e) => {
                  if (e.target.files[0].size > 10485760) {
                    e.target.value = null
                    dispatch(userActions.notification({
                      message: 'Dung lượng vượt quá hơn 10MB',
                      type: 'error'
                    }));
                  } else {
                    handleFileChange(e.target.files[0], value);
                  }
                }}
              />
            </Card>
            {emptyKey === `upload${value[0].toUpperCase() + value.slice(1)}` && !files?.[value] && (
              <Typography style={{ fontSize: 12, color: '#f9625b' }}>Không được để trống</Typography>
            )}
          </>
        )}
      </>
    );
  }

  const textForm = {
    base: [
    { key: 'name', width: isMobileMode ? 12 : 8, label: 'Họ và tên', value: profile?.name, required: true, disabled: true },
      {
        key: 'birth',
        width: isMobileMode ? 12 : 4,
        label: 'Ngày sinh',
        value: dayjs(profile?.birth).format('DD/MM/YYYY'),
        type: 'date',
        disabled: true,
        required: true,
      },
      { key: 'ethnic', width: isMobileMode ? 12 : 4, label: 'Dân tộc', value: profile?.ethnic, disabled: true, required: true },
      { key: 'phone', width: isMobileMode ? 12 : 8, label: 'Số điện thoại', value: profile?.phone, required: true, disabled: true },
      { key: 'email', width: 12, label: 'Email', value: profile?.email, required: true, disabled: true },
      { key: 'website', width: 12, height: 1, label: 'Website/Facebook', required: true, value: profile?.website, disabled: true },
    ],
    partI: [
      { key: 'nominator', width: 12, height: 1, label: 'Đơn vị đề cử', required: true, value: values?.nominator },
      { key: 'nominatorPhone', width: isMobileMode ? 12 : 5, label: 'Điện thoại liên hệ', required: true, value: values?.nominatorPhone },
      {
        key: 'uploadNominator',
        width: isMobileMode ? 12 : 7,
        label: 'File công văn giới thiệu của đơn vị đề cử',
        render: renderUploadFile(
          <Typography className="kpt-textfield-label" style={{ display: "inline" }}>
            {`Upload file Công văn giới thiệu của đơn vị đề cử (Tải file mẫu `}
              <a
                href={fileCongVanMau}
                style={{ color: SECONDARY_COLOR }}
              >
                tại đây
              </a>
              {")"}
          </Typography>,
          'nominator'
        ),
        required: true,
        value: files?.nominator,
      },
      { key: 'videoUrl', width: 12, label: 'Link video giới thiệu bản thân', required: true },
      {
        key: "previewFileLabel",
        width: 12,
        value: true,
        render: (
          <Typography className="kpt-textfield-label" style={{ marginBottom: -8 }}>
            05 ảnh ứng viên tham gia các hoạt động học tập, nghiên cứu khoa học, hoạt động vì cộng đồng
            <span style={{ color: theme.palette.secondary.main }}>*</span>:
          </Typography>
        )
      },
      {
        key: "uploadSocialActivity",
        width: 12,
        label: 'Upload tại đây',
        type: 'file',
        filesLimit: 5,
        value: files?.socialActivity || values?.uploadSocialActivity,
        acceptedFiles: ['image/*'],
        required: true,
      },
    ],
    partII: [
      {
        key: 'politicalUnion',
        width: isMobileMode ? 6 : 3,
        label: 'Đoàn thể chính trị',
        required: true,
        options: POLITICAL_UNION_OPTIONS,
      },
      {
        key: 'bonus',
        width: isMobileMode ? 6 : 3,
        label: 'Điểm ưu tiên',
        required: true,
        options: [
          { key: 'khong', title: 'Không', value: 'khong' },
          { key: 'khuyet-tat', title: 'Là nữ sinh viên khuyết tật', value: 'khuyet-tat' },
        ],
      },
      { key: 'languague', width: isMobileMode ? 12 : 6, label: 'Ngoại ngữ', required: true },
      { key: 'gifted', width: 12, height: 2, label: 'Năng khiếu', required: true },
      { key: 'university', width: 12, label: 'Tên trường/học viện đang theo học', required: true },
    ],
    partIII: [
      { key: 'faculty', width: 12, label: 'Tên Khoa/Viện', required: true },
      { key: 'facultyPhone', width: 12, label: 'Điện thoại liên hệ Đoàn trường/học viện', required: true },
      { key: 'studentYear', width: isMobileMode ? 12 : 2, label: 'Sinh viên năm thứ', type: 'int', required: true },
      { key: 'major', width: isMobileMode ? 12 : 5, label: 'Ngành học', required: true },
      {
        key: 'field',
        width: isMobileMode ? 12 : 5,
        label: 'Nhóm ngành đăng ký',
        options: FORM_CONSTANTS.FIELD_PTNS_OPTIONS,
        required: true,
      },
      { key: 'gpa', width: isMobileMode ? 12 : 5, label: 'Điểm TB chung học tập (theo thang điểm 4)', type: 'float', required: true },
      {
        key: 'uploadGpa',
        width: isMobileMode ? 12 : 7,
        label: 'File bảng điểm học tập',
        render: renderUploadFile('File Bảng điểm học tập', 'gpa'),
        value: files?.gpa,
        required: true,
      },
    ],
  };

  const topPart = isMobileMode ? textForm.base.slice(0, 4) : textForm.base;
  const botPart = isMobileMode ? textForm.base.slice(4, textForm.base.length) : [];

  const archivementFields = [
    {
      index: '1',
      type: ARCHIVEMENT_TYPE.SOLUTION,
      title: 'Có bằng độc quyền sáng chế, giải pháp hữu ích thuộc các nhóm ngành xét chọn',
      data: achievementData[ARCHIVEMENT_TYPE.SOLUTION]?.map((e) => ({ ...e, issueAt: dayjs(e?.issueAt).format('DD/MM/YYYY') })),
    },
    {
      index: '2',
      type: ARCHIVEMENT_TYPE.SOLUTION + CATEGORY.PTNS,
      title: 'Có sáng chế/ giải pháp hữu ích đã được chấp nhận đơn hợp lệ thuộc các nhóm ngành xét chọn',
      data: achievementData[ARCHIVEMENT_TYPE.SOLUTION + CATEGORY.PTNS]?.map((e) => ({ ...e, issueAt: dayjs(e?.issueAt).format('DD/MM/YYYY') })),
    },
    {
      index: '3',
      title: 'Có bài báo khoa học đăng trên tạp chí quốc tế/quốc gia thuộc các ngành xét giải',
      children: [
        {
          index: '3.1',
          type: ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL,
          title: 'Bài báo khoa học trên tạp chí uy tín quốc tế ',
          data: achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
        },
        {
          index: '3.2',
          type: ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC,
          title:
            'Bài báo khoa học đăng trên tạp chí trong nước (các tạp chí thuộc danh mục tính điểm của Hội đồng chức danh GSNN)',
          data: achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
          defaultData: { type: 'q6' }
        },
      ],
    },
    {
      index: '4',
      title: 'Có bài báo khoa học đăng trên hội thảo quốc tế/quốc gia thuộc các ngành xét giải',
      type: ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT,
      data: achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT],
    },
    {
      index: '5',
      title: 'Tham gia nghiên cứu khoa học thuộc các ngành xét giải',
      children: [
        {
          index: '5.1',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB,
          title: 'Đề tài nghiên cứu khoa học cấp Bộ đã nghiệm thu đạt',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB],
        },
        {
          index: '5.2',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT,
          title: 'Đề tài nghiên cứu khoa học cấp Trường đã nghiệm thu đạt',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT],
        },
      ],
    },
    {
      index: '6',
      title: 'Đạt Huy chương/Giải thưởng thuộc các ngành xét giải',
      children: [
        {
          index: '6.1',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT,
          title: 'Huy chương/Giải thưởng cấp quốc tế',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT],
        },
        {
          index: '6.2',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG,
          title: 'Huy chương/Giải thưởng cấp quốc gia',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG],
        },
        {
          index: '6.3',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS,
          title: 'Huy chương/Giải thưởng cấp tỉnh, thành, ngành',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS],
        },
      ],
    },
  ];

  const activityFields = [
    {
      key: KEYS.MAJOR_ACHIEVEMENT,
      values: majorAchievement,
      setValues: majorAchievement,
      label: isMobileMode
        ? "Thành tích trong lĩnh vực chuyên ngành"
        : "Thành tích trong lĩnh vực chuyên ngành(các hoạt động học tập, nghiên cứu liên quan đến chuyên ngành)",
      fields: [
        { key: `${KEYS.MAJOR_ACHIEVEMENT}Content`, width: isMobileMode ? 12 : 4, label: "Hình thức và nội dung thành tích", required: true },
        { key: `${KEYS.MAJOR_ACHIEVEMENT}Type`, width: isMobileMode ? 12 : 3, label: "Cấp thành tích", options: FORM_CONSTANTS.LEVEL_INPUT_OPTIONS, required: true },
        { key: `${KEYS.MAJOR_ACHIEVEMENT}PreviewFile`, label: "Minh chứng", width: isMobileMode ? 12 : 4, type: "file", required: true },
      ],
    },
    {
      key: KEYS.REWARD,
      values: reward,
      setValues: setReward,
      label: isMobileMode
        ? 'Thành tích khen thưởng'
        : 'Thành tích khen thưởng (Bằng khen, Giấy khen... xếp thứ tự từ khen thưởng cao xuống thấp)',
      fields: [
        { key: `${KEYS.REWARD}Content`, width: isMobileMode ? 12 : 4, label: "Nội dung khen thưởng", required: true },
        { key: `${KEYS.REWARD}Type`, width: isMobileMode ? 12 : 3, label: "Cấp khen thưởng", options: FORM_CONSTANTS.LEVEL_INPUT_OPTIONS, required: true },
        { key: `${KEYS.REWARD}PreviewFile`, label: "Minh chứng", width: isMobileMode ? 12 : 4, type: "file", required: true },
      ],
    },
    {
      key:  KEYS.SOCIAL_ACTIVITY,
      values: socialActivity,
      setValues: setSocialActivity,
      label: 'Thành tích hoạt động Đoàn, Hội, Đội',
      fields: [
        { key: `${KEYS.SOCIAL_ACTIVITY}Content`, width: isMobileMode ? 12 : 7, label: 'Hình thức và nội dung thành tích', required: true },
        { key: `${KEYS.SOCIAL_ACTIVITY}PreviewFile`, width: isMobileMode ? 12 : 4, label: 'Minh chứng', type: 'file', required: true },
      ],
    },
  ];

  const newFileLength = useMemo(() => {
    const len = {
      [KEYS.SOCIAL_ACTIVITY]: 0,
      [KEYS.PERSONAL_PROCESS]: 0,
      [KEYS.OTHER_ACHIEVEMENT]: 0,
    }
    Object.keys(files).forEach((key) => {
      if (key.includes(KEYS.SOCIAL_ACTIVITY)) len[KEYS.SOCIAL_ACTIVITY] = len[KEYS.SOCIAL_ACTIVITY] + 1;
      if (key.includes(KEYS.PERSONAL_PROCESS)) len[KEYS.PERSONAL_PROCESS] = len[KEYS.PERSONAL_PROCESS] + 1;
      if (key.includes(KEYS.OTHER_ACHIEVEMENT)) len[KEYS.OTHER_ACHIEVEMENT] = len[KEYS.OTHER_ACHIEVEMENT] + 1;
    });
    return len;
  }, [files]);

  const handleChangeActivityForm = (name, event, type, values, setValues) => {
    const keyList = name.split(".");
    const key = keyList[2];
    const index = parseInt(keyList[1]);

    const tmp = [...values];
    if(type === "date"){
      tmp.splice(index, 1, { ...tmp[index], [key]: event});
    } else if (type === "removeFile") {
      tmp.splice(index, 1, { ...tmp[index], [key]: undefined }); 
    } else {
      tmp.splice(index, 1, { ...tmp[index], [key]: event.target.value });
    }
    setValues(tmp);
  };

  const handleReload = (profileId) => {
    dispatch(formActions.getApplicationFormv2(profileId));
    dispatch(formActions.filterApplicationForm({
      category: APP_SCENE,
      seasonId: openSeasonId,
      profileId: profile.id,
    })).then((applications) => {
      if (applications.length > 0) {
        const application = applications[0];

        if (application.reward === "") application.reward = "[]";
        if (application.previewFile === "") application.previewFile = "[]";
        if (application.socialActivity === "") application.socialActivity = "[]";
        if (application.majorAchievement === "") application.majorAchievement = "[]";

        const fileList = JSON.parse(application.previewFile);
        application.uploadGpa = fileList.find((file) => file.includes(('gpa')));
        application.uploadNominator = fileList.find((file) => file.includes(('nominator')));
        application.uploadCurriculum = fileList.find((file) => file.includes(('curriculum')));
        application.uploadSocialActivity = fileList.filter((file) => file.includes(('socialActivity'))).map((file) => photoRender(file));

        if (application) {
          setValues({ ...values, ...application });
          setReward(JSON.parse(application.reward) || []);
          setSocialActivity(JSON.parse(application.socialActivity) || []);
          setMajorAchievement(JSON.parse(application.majorAchievement) || []);
        }
      }
    });
  }

  const upsertApplication = (status, uploadedFiles=[]) => {
    const parseJsonActivity = (key) => {
      let dataSource = [];
      if (key === KEYS.REWARD) dataSource = [...reward];
      else if (key === KEYS.SOCIAL_ACTIVITY) dataSource = [...socialActivity];
      else if (key === KEYS.MAJOR_ACHIEVEMENT) dataSource = [...majorAchievement];

      dataSource = dataSource
        .filter((data) => Object.values(data).length !== 0)
        .map((data, index) => ({
          ...data,
          [`${key}PreviewFile`]: uploadedFiles.find((file) => {
            const keys = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1].split('.');
            return keys[0] === key && parseInt(keys[1]) === index;
          }) || data?.[`${key}PreviewFile`] || "",
        }));
      
      return dataSource ? JSON.stringify(dataSource) : ''
    };

    const achivementFormData = {};
    [
      ARCHIVEMENT_TYPE.REWARD,
      ARCHIVEMENT_TYPE.SOLUTION,
      ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT,
      ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH,
    ].forEach((key) => {
      achivementFormData['form' + capitalizeFirstLetter(key)] = achievementData[key].map((e) => ({ [key + 'Id']: e.id }))
    });

    var previewFile = JSON.parse(application?.previewFile || '[]');
    const sumaryFiles = uploadedFiles?.filter((file) => file.includes('socialActivity') || file.includes('gpa') || file.includes('nominator') || file.includes('curriculum'));
    if (sumaryFiles.length > 0) previewFile = sumaryFiles;

    const payload = {
      ...values,
      ...achivementFormData,
      status,
      seasonId: openSeasonId,
      category: APP_SCENE,
      profileId: profile.id,
      previewFile: JSON.stringify(previewFile),

      [KEYS.REWARD]: parseJsonActivity(KEYS.REWARD),
      [KEYS.SOCIAL_ACTIVITY]: parseJsonActivity(KEYS.SOCIAL_ACTIVITY),
      [KEYS.MAJOR_ACHIEVEMENT]: parseJsonActivity(KEYS.MAJOR_ACHIEVEMENT),
    }

    // Hồ sơ rỗng thì tạo hồ sơ mới, không thì cập nhật đè lên hồ sơ cũ
    if (!application) {
      dispatch(formActions.insertApplicationForm(payload)).then((success) => {
        if (success && status === 'in_review') window.location.reload();
      });
    } else {
      dispatch(formActions.updateApplicationForm(payload)).then((success) => {
        if (success && status === 'in_review') window.location.reload();
      });
    }
  }

  const handleSave = (status) => {
    const formFields = [
      ...textForm.partI,
      ...textForm.partII,
      ...textForm.partIII,
    ];

    for (const elm of formFields) {
      if (status === "unsubmit") continue;

      const value = elm?.value || values?.[elm.key];
    
      if (!elm.disabled && elm.required && !value) {
        setValues({ ...values, emptyKey: elm.key });
        jumpToReleventDiv(elm.key);
        dispatch(
          userActions.notification({
            message: `Không được để trống thông tin về: ${elm?.renderLabel || elm?.label}`,
            type: 'error',
          }),
        );
        return;
      }
    }

    for (const active of activityFields) {
      if (status === "unsubmit") continue;

      for (const field of active.fields) {
        if (field.required) {
          let key = field.key;
          for (var i = 0; i < active.values.length; i++) {
            const value = active.values[i];
            if ((!value[key] || value[key] == '[]') && !files?.[`${active.key}.${i}.${key}`]) {
              setEmptyKey(key);
              dispatch(
                userActions.notification({
                  message: `Không được để trống thông tin về: ${field.label}`,
                  type: 'error',
                }),
              );
              return;
            }
          }
        }
      }
    }

    if (!profile?.id) {
      dispatch(userActions.notification({ message: "Lỗi profile ID, vui lòng tải lại trang!", type: 'error' }));
      return false;
    }

    const uploadFiles = {...files};
    values.uploadSocialActivity?.forEach((value, index) => uploadFiles[`socialActivity.${index}`] = value);

    if (Object.keys(uploadFiles).length > 0) {
      setUploadingFiles(true);
      dispatch(fileActions.importMultiFileQCV(profile.id, uploadFiles)).then((uploadedFiles) => {
        setUploadingFiles(false);
        if (!uploadedFiles) {
          dispatch(userActions.notification({ message: "Lỗi upload files, vui lòng tải lại trang!", type: 'error' }));
          return;
        }
        upsertApplication(status, uploadedFiles)
      });
    } else {
      upsertApplication(status);
    }

    return true;
    
    // setUploadFiles(true);

    // dispatch(fileActions.importMultiFileQCV(profile?.id, file)).then((response) => {
    //   setUploadFiles(false);

    //   const gpa = response?.find((file) => file.includes('gpa')) || values?.uploadGpa;
    //   const nominator = response?.find((file) => file.includes('nominator')) || values?.uploadNominator;
    //   const curriculum = response?.find((file) => file.includes('curriculum')) || values?.uploadNominator;
    //   const socialActivity = response?.find((file) => file.includes('socialActivity')) || values?.activityFile;

    //   const payload = {
    //     ...values,
    //     seasonId: openSeasonId,
    //     profileId: profile?.id,
    //     category: 'nstb',
    //     status,
    //     reward: reward
    //       ? JSON.stringify(
    //           reward
    //             ?.filter((elm) => Object.values(elm).length !== 0)
    //             ?.map((e, index) => ({
    //               ...e,
    //               rewardFile:
    //                 response?.find((file) => {
    //                   const name = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1];

    //                   const keyList = name.split('.');
    //                   return keyList[0] === 'reward' && parseInt(keyList[1]) === index;
    //                 }) ||
    //                 e?.rewardFile ||
    //                 '[]',
    //             })) || '',
    //         )
    //       : '',
    //     socialActivity: socialActivity
    //       ? JSON.stringify(
    //           socialActivity
    //             ?.filter((elm) => Object.values(elm).length !== 0)
    //             ?.map((e, index) => ({
    //               ...e,
    //               socialActivityFile:
    //                 response?.find((file) => {
    //                   const name = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1];
    //                   const keyList = name.split('.');
    //                   return keyList[0] === 'socialActivity' && parseInt(keyList[1]) === index;
    //                 }) ||
    //                 e?.socialActivityFile ||
    //                 '[]',
    //             })) || '',
    //         )
    //       : '',
    //     majorAchievement: majorAchievement
    //       ? JSON.stringify(
    //           majorAchievement
    //             ?.filter((elm) => Object.values(elm).length !== 0)
    //             ?.map((e, index) => ({
    //               ...e,
    //               majorAchievementFile:
    //                 response?.find((file) => {
    //                   const name = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1];

    //                   const keyList = name.split('.');
    //                   return keyList[0] === 'majorAchievement' && parseInt(keyList[1]) === index;
    //                 }) ||
    //                 e?.majorAchievementFile ||
    //                 '[]',
    //             })) || '',
    //         )
    //       : '',
    //     previewFile: [gpa, nominator, curriculum, socialActivity].join(','),
    //     formReward: rewards.map((e) => ({ rewardId: e.id })),
    //     formSolution: achievementData[ARCHIVEMENT_TYPE.SOLUTION].map((e) => ({ solutionId: e.id })),
    //     formScientificResearch: [
    //       ...achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
    //       ...achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
    //     ].map((e) => ({ scientificResearchId: e.id })),
    //     // formMonograph: monographList.map((e) => ({ monographId: e.id })),
    //     // formTechCompany: techCompanyList.map((e) => ({ techCompanyId: e.id })),
    //     // formScientificReport: achievementData[].map((e) => ({  scientificReportId: e.id })),
    //     // formResearchProject: researchProjectList.map((e) => ({ researchProjectId: e.id })),
    //     // formHrDevelopment: hrDevelopmentList.map((e) => ({ hrDevelopmentId: e.id })),
    //   };
    //   // Hồ sơ rỗng thì tạo hồ sơ mới, không thì cập nhật đè lên hồ sơ cũ
    //   if (!application) {
    //     dispatch(formActions.insertApplicationForm(payload)).then(() => {
    //       // if (status === 'in_review')
    //       // window.location.reload();
    //     });
    //   } else {
    //     dispatch(formActions.updateApplicationForm(payload)).then(() => {
    //       // if (status === 'in_review') window.location.reload();
    //     });
    //   }
    // });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave('in_review');
  };

  const openSeason = useMemo(() => seasonList.find((s) => s.id === openSeasonId), [seasonList, openSeasonId]);

  const isNotAvalaible = useMemo(() => {
    if (!openSeason) return false;
    const { openDate, closeDate } = openSeason;
    const currDate = new Date();
    if (currDate < new Date(openDate)) return 'Chưa mở cổng đăng ký';
    if (currDate > new Date(closeDate)) return 'Đã hết thời hạn đăng ký';
    return null;
  }, [openSeason]);

  // Vừa vào trang thì lấy thông tin hồ sơ luôn
  useEffect(() => {
    if (openSeasonId && profile?.id) handleReload(profile?.id);
  }, [openSeasonId, profile]);

  useEffect(() => {
    if (values?.activityFile) handleFileChange(values?.activityFile, 'socialActivity');
  }, [values?.activityFile]);

  // if (!values) return null;

  return (
    <>
      <Card style={{ width: '100%', marginTop: 64 }}>
        <Box height={6} bgcolor='#233871' />
        <Box padding={2}>
          <Typography variant='h4' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
            <b>Đăng ký hồ sơ</b>
          </Typography>
        </Box>
      </Card>

      <form className='dkhs-container' id='form-update' defaultValue={values} onSubmit={handleSubmit}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Box display='flex'>
              <Box flexGrow={1} marginTop='4px'>
                <KPTextform component='div' textForm={topPart} />
              </Box>
              <Box marginTop='4px' marginLeft={1}>
                <Typography className='kpt-textfield-label'>
                  {isMobileMode ? 'Ảnh chân dung' : 'Ảnh chân dung (4x6)'}
                  <span style={{ color: theme.palette.secondary.main }}>*</span>
                  {':'}
                </Typography>
                <Box>
                  <Card className='dkhs-photoContainer'>
                    <img alt='anh-chan-dung' className='dkhs-photo' src={photoRender(profile?.photoUrl)} />
                  </Card>
                </Box>
              </Box>
            </Box>
            <Box flexGrow={1} marginTop='4px'>
              <KPTextform component='div' textForm={botPart} />
            </Box>
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 32 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.partI}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 32 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.partII}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 32 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.partIII}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>

        <Card style={{ width: '100%', marginTop: 96 }}>
          <Box height={6} bgcolor='#233871' />
          <Box padding={2}>
            <Typography variant='h5' style={{ textAlign: 'center' }}>
              <b>THÀNH TÍCH KHOA HỌC CÔNG NGHỆ</b>
            </Typography>
          </Box>
        </Card>

        {archivementFields.map((e, id) => (
          <Card key={e?.index} style={{ width: '100%', marginTop: 32 }}>
            <Box padding={e.type ? '2px 4px 2px 16px' : '8px 16px'} display='flex' alignItems='center'>
              <Typography variant='h6' style={{ flexGrow: 1 }}>
                <b>{`${e?.index}. ${e?.title}`}</b>
              </Typography>
              {e.type && (
                <>
                  <Tooltip arrow title={<ThanhTichAlert closable={false} />}>
                    <Info style={{ cursor: "pointer" }} />
                  </Tooltip>
                  <IconButton
                    color='primary'
                    onClick={() => setDialog({ type: e?.type, title: e?.title })}
                  >
                    <Add />
                  </IconButton>
                </>
              )}
            </Box>
            {e.type ? (
              <>
                <Divider />
                <KPTable nonBorder={true} columns={columnRender(e, setDialog, setPreviewFile)} rows={e.data || []} />
              </>
            ) : (
              <>
                {e.children.map((item) => (
                  <>
                    <Divider />
                    <Box padding='2px 4px 2px 16px' display='flex' alignItems='center'>
                      <Typography variant='h6' style={{ flexGrow: 1 }}>
                        <b>{`${item?.index}. ${item?.title}`}</b>
                      </Typography>
                      <Tooltip arrow title={<ThanhTichAlert closable={false} />}>
                        <Info style={{ cursor: "pointer" }} />
                      </Tooltip>
                      <IconButton
                        color='primary'
                        onClick={() =>
                          setDialog({
                            type: item?.type,
                            title: item?.title,
                            data: item?.defaultData,
                          })
                        }
                      >
                        <Add />
                      </IconButton>
                    </Box>
                    <Divider />
                    <KPTable nonBorder={true} columns={columnRender(item, setDialog, setPreviewFile)} rows={item.data || []} />
                  </>
                ))}
              </>
            )}
          </Card>
        ))}

        <Card style={{ width: '100%', marginTop: 96 }}>
          <Box height={6} bgcolor='#233871' />
          <Box padding={2}>
            <Typography variant='h5' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
              <b>Thành tích khen thưởng và hoạt động Đoàn, Hội, Đội</b>
            </Typography>
          </Box>
        </Card>

        {activityFields.map((active, index) => (
          <Card key={active.key} style={{ width: '100%', marginTop: 32 }}>
            <InputActives
              id={active.key}
              {...active}
              newFileLength={newFileLength[active.key]}
              setPreview={setPreviewFile}
              handleFileChange={handleFileChange}
              handleChangeForm={handleChangeActivityForm}
            />
          </Card>
        ))}

        <Card style={{ width: '100%', padding: 16, marginTop: 32 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                <i>
                  <b>Lưu ý: </b>Công văn giới thiệu, bảng điểm, bằng khen, giấy khen, giấy chứng nhận hay các văn
                  bản có liên quan làm minh chứng phải được scan bản gốc hoặc có chữ ký, đóng dấu đỏ của đơn vị chủ
                  quản.
                </i>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox color='secondary' checked={acceptRules} onChange={() => setAcceptRules(!acceptRules)} />
                }
                label={
                  <Typography variant='subtitle1'>
                    <b style={{ fontFamily: 'SFProDisplay' }}>
                      Tôi xin hoàn toàn chịu trách nhiệm trước pháp luật, Ban tổ chức Giải thưởng về tính chính xác
                      và các thông tin đã khai trong hồ sơ đăng ký trực tuyến tham gia xét giải thưởng
                    </b>
                  </Typography>
                }
                style={{ marginTop: 48 }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth disabled={!acceptRules} variant='outlined' onClick={() => handleSave('unsubmit')}>
                Lưu bản nháp
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                disabled={!acceptRules}
                form='form-update'
                type='submit'
                color='secondary'
                variant='contained'
              >
                Nộp hồ sơ
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>

      <ThanhTichInsert dialog={dialog} setDialog={setDialog} />

      <Loading open={loading} />
      <PreviewModal open={!!previewFile} url={previewFile} handleClose={() => setPreviewFile(null)} />

      <Dialog fullWidth maxWidth="xs" open={!loading && (isNotAvalaible || warningOpen)}>
        <DialogTitle style={{ padding: "4px 12px 4px 24px" }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant="h5"><b>Thông báo</b></Typography>
            {!isNotAvalaible && (
              // Chưa đóng cổng thì có thể tắt
              <IconButton onClick={() => setWarningOpen(false)}><Close /></IconButton>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" width="100%" marginBottom={4}>
            <WarningRounded style={{ margin: "auto", color: "#4da6ff", fontSize: 72 }} />
          </Box>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {isNotAvalaible ? (
              <>
                {isNotAvalaible}
                <br />
                <b>Giải thưởng Nữ sinh KHCN năm {openSeason?.year}</b>
                </>
            ) : (
              <b>{application?.status === "in_review" && "Hồ sơ đã được nộp trên hệ thống!"}</b>
            )}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "8px 24px" }}>
          {isNotAvalaible ? (
            <Button fullWidth color="secondary" variant="contained" href={ROUTER.HOME}>
              Về trang chủ
            </Button>
          ) : (
            <>
              {application?.status === "in_review" && (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      href={ROUTER.QCV.HOME}
                      variant="outlined"
                      startIcon={<Home />}
                      onClick={() => setWarningOpen(false)}
                    >
                      Trang chủ
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      startIcon={<Edit />}
                      style={{ float: "right" }}
                      onClick={() => setWarningOpen(false)}
                    >
                      Chỉnh sửa hồ sơ
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
