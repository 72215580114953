import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import KPTextfield from '../KPTextfield';
import KPTimepicker from '../KPTimepicker';
import { userActions } from '../../../actions';
import { validEmail } from '../../../utils/validate';
import { jumpToReleventDiv } from '../utils';

function KPTextform(props) {
  const dispatch = useDispatch();
  const [emptyKey, setEmptyKey] = React.useState(null);
  const { rowMargin, textForm, values, setValues } = props;

  const handleChangeForm = (name, event, type) => {
    if (type === 'date') {
      setValues({ ...values, [name]: event });
    } else if (type === 'richText') {
      setValues({ ...values, [name]: event || ' ' })
    } else if (type === 'file') {
      setValues({ ...values, [name]: event });
    } else if (type === 'int') {
      setValues({ ...values, [name]: parseInt(event.target.value) });
    } else if (type === 'float') {
      setValues({ ...values, [name]: parseFloat(event.target.value) });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    for (const elm of textForm) {
      const elmValue = elm?.value || values?.[elm?.key];
      if (elm?.required && (!elmValue && elmValue !== 0)) {
        setEmptyKey(elm.key);
        jumpToReleventDiv(elm.key);
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label}`,
          type: 'error'
        }));
        return;
      }

      if (elm?.required && elm?.type === 'file' && elmValue.length !== 1) {
        setEmptyKey(elm.key);
        jumpToReleventDiv(elm.key);
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label || 'File minh chứng'}`,
          type: 'error'
        }));
        return;
      }

      if (elm?.type === 'email' && !validEmail(elmValue)) {
        setEmptyKey(elm.key);
        jumpToReleventDiv(elm.key);
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label}`,
          type: 'error'
        }));
        return;
      }
    }

    props.handleSubmit();
  }

  const empty = (value, type) => {
    if (type === 'int' || type === 'float') {
      return !isNaN(value) ? 0 : '';
    } else if (type === 'richText') {
      return value || ' ';
    }
    return '';
  };

  return (
    <Box component={props?.component || 'form'} id={props.id} onSubmit={handleSubmit}>
      <Grid container spacing={1} justifyContent='center'>
        {textForm.map((elm) => (
          elm.type !== 'date' ? (
            ((elm.type === 'file' && elm?.disabled) || elm?.render === 'none') ? (
              null
            ) : (
              <Grid item xs={elm.width} key={elm.key} style={{ marginTop: rowMargin || 'unset', marginBottom: rowMargin || 'unset' }}>
                <KPTextfield
                  {...elm}
                  id={elm.key}
                  value={elm?.value || values?.[elm.key] || empty(values?.[elm.key], elm.type)}
                  error={!elm?.disabled && (elm.key === emptyKey || elm.key === values?.emptyKey)}
                  handleChangeForm={props.handleChangeForm || handleChangeForm}
                />
              </Grid>
            )
          ) : (
            <Grid item xs={elm.width} key={elm.key} style={{ marginTop: rowMargin || 'unset', marginBottom: rowMargin || 'unset' }}>
              <KPTimepicker
                {...elm}
                id={elm.key}
                value={elm?.value || values?.[elm.key] || 'dd/mm/yyyy'}
                error={!elm?.disabled && (elm.key === emptyKey)}
                setValue={(e) => handleChangeForm(elm.key, e, 'date')}
              />
            </Grid>
          )
        ))}
      </Grid>
    </Box>
  );
}

export default KPTextform;
